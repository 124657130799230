import React from 'react';
import { isFeatureEnabled } from '../shared/helpers/features';

type Props = {
  tracker: string;
};

const TrackingImage = ({ tracker }: Props) => {
  return (
    isFeatureEnabled('analytics') && (
      <img
        alt=""
        decoding="async"
        style={{
          width: '1px',
          height: '1px',
          position: 'absolute',
          bottom: '100%',
          left: '100%',
        }}
        src={tracker + location.search}
      />
    )
  );
};

export default TrackingImage;
