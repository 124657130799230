import client from '@mc/networking/http';

export const ResponseStatus = {
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
};

export function respondToSurvey(
  userId: $TSFixMe,
  surveyId: $TSFixMe,
  emailId: $TSFixMe,
  attribution: $TSFixMe,
  surveyFormData: $TSFixMe,
) {
  const formData = client.convertJsonToFormData({ answers: surveyFormData });
  const url = client.query('/survey/respond', {
    u: userId,
    id: surveyId,
    e: emailId,
    attribution: attribution,
  });
  return client.post(url, formData).then((response: $TSFixMe) => {
    if (response.ok || response.status === 400) {
      return response.json();
    }
    return Promise.reject(new Error('Error submitting survey'));
  });
}

export function editResponse(
  userId: $TSFixMe,
  surveyId: $TSFixMe,
  emailId: $TSFixMe,
  attribution: $TSFixMe,
  surveyFormData: $TSFixMe,
  status = ResponseStatus.DRAFT,
) {
  const formData = client.convertJsonToFormData({
    answers: surveyFormData,
    status,
  });
  const url = client.query('/survey/response/edit', {
    u: userId,
    id: surveyId,
    e: emailId,
    attribution: attribution,
  });
  return client.post(url, formData).then((response: $TSFixMe) => {
    if (response.ok || response.status === 400) {
      return response.json();
    }
    return Promise.reject(new Error('Error editing survey'));
  });
}

export function validateResponse(
  userId: $TSFixMe,
  surveyId: $TSFixMe,
  emailId: $TSFixMe,
  attribution: $TSFixMe,
  surveyFormData: $TSFixMe,
  status = ResponseStatus.DRAFT,
) {
  const formData = client.convertJsonToFormData({
    answers: surveyFormData,
    status,
  });
  const url = client.query('/survey/validation/validate', {
    u: userId,
    id: surveyId,
    e: emailId,
    attribution: attribution,
  });
  return client.post(url, formData).then((response: $TSFixMe) => {
    if (response.ok || response.status === 400) {
      return response.json();
    }
    return Promise.reject(new Error('Error editing survey'));
  });
}
