import React, { useEffect } from 'react';
import cx from 'classnames';
import { Bugsnag } from '@mc/bugsnag';
import stylesheet from './ErrorScreen.css';

type Props = {
  error?: $TSFixMe;
  isLoggable?: boolean;
};

const ErrorScreen = ({ error = null, isLoggable = false }: Props) => {
  useEffect(() => {
    if (isLoggable && error) {
      const errorMessage = `Hosted survey ErrorScreen outside boundary hit: ${
        error?.message || JSON.stringify(error)
      }`;

      Bugsnag.notify(new Error(errorMessage));
    } else {
      Bugsnag.notify(
        new Error(
          'Hosted survey ErrorScreen outside boundary hit: No error message given',
        ),
      );
    }
  }, [error, isLoggable]);

  return (
    <div className={stylesheet.container}>
      <img
        className={stylesheet.illustration}
        src="https://cdn-images.mailchimp.com/error-pages/art-hero-down.png"
        alt=""
        role="presentation"
      />
      <h2 className={stylesheet.heading}>Something's gone wrong</h2>
      <p>We're working on it and hope to have it fixed soon.</p>
      <button
        className={cx(
          '-mc-wink-components-ButtonOrLink-ButtonOrLink-root',
          '-mc-wink-components-Button-Button-container',
          '-mc-wink-components-Button-Button-primary',
          stylesheet.button,
        )}
        type="button"
        onClick={() => window.location.reload()}
      >
        Retry
      </button>
    </div>
  );
};

export default ErrorScreen;
