import useQueryToState, {
  CLEAR_ONCE_IN_MEMORY,
} from '../../shared/hooks/useQueryToState';

function delegateTypeResponseData(
  questionId: $TSFixMe,
  responseValue: $TSFixMe,
  firstQuestion: $TSFixMe,
) {
  if (!questionId || !firstQuestion || !firstQuestion.id) {
    return {};
  }
  if (firstQuestion.id.toString() !== questionId.toString()) {
    return {};
  }
  switch (firstQuestion.response) {
    case 'range': {
      const response = +responseValue;
      return isNaN(response)
        ? null
        : {
            [questionId]: { answer: response },
          };
    }
    default:
      return {};
  }
}

function pullQuestions(sections: $TSFixMe) {
  return sections
    .filter((section: $TSFixMe) => {
      return section.type === 'question';
    })
    .map((section: $TSFixMe) => {
      return section.question;
    });
}

function useQueryResponse(sections: $TSFixMe) {
  const questions = pullQuestions(sections);
  const { isLoading: isLoadingQuestion, value: questionId } = useQueryToState(
    't',
    null,
    { clear: CLEAR_ONCE_IN_MEMORY },
  );
  const { isLoading: isLoadingResponse, value: responseValue } =
    useQueryToState('r', null, { clear: CLEAR_ONCE_IN_MEMORY });
  return {
    isLoading: isLoadingQuestion && isLoadingResponse,
    data: {
      questions: delegateTypeResponseData(
        questionId,
        responseValue,
        questions[0],
      ),
    },
  };
}

export default useQueryResponse;
