import React, { Fragment, useState } from 'react';
import SurveyForm from '../shared/Form';
import surveyVariables from '../shared/styles/survey-variables.css';
import {
  registerFeatures,
  isFeatureEnabled,
} from './../shared/helpers/features';
import SurveyContainer from './SurveyContainer';
import { respondToSurvey, validateResponse } from './api';
import { ClosedMessage, CompletionMessage } from './Messages';
import SurveyHeader from './SurveyHeader';
import TrackingImage from './TrackingImage';
import useQueryResponse from './hooks/useQueryResponse';
import './App.css';
/**
 * @type {array<Promise>}
 */
const changeStack: $TSFixMe = [];

function changeStackDefault() {
  return { current: null, changes: [] };
}

type Props = {
  attribution?: boolean;
  completeTracking?: string;
  emailId?: string;
  features: $TSFixMe;
  openTracking?: string;
  surveyData: $TSFixMe;
  surveyId: string;
  userId: string;
};

const App = ({
  attribution,
  emailId,
  features,
  surveyData,
  surveyId,
  userId,
  openTracking,
  completeTracking,
}: Props) => {
  registerFeatures(features);
  const { isLoading, data: queryData } = useQueryResponse(surveyData.sections);
  const [isCompleted, setCompleted] = useState(false);
  const [stackTarget] = useState(changeStack.length);
  if (!changeStack[stackTarget]) {
    changeStack[stackTarget] = changeStackDefault();
  }

  const [errors, setErrors] = useState({});
  const [isClosed, setIsClosed] = useState(surveyData.hostedStatus !== 'on');

  const getErrorsFromData = (data: $TSFixMe) => {
    const formErrors = {};
    Object.keys(data.errors).forEach((questionId) => {
      const errorKey = `questions.${questionId}.answer`;
      // @ts-expect-error TS7053
      formErrors[errorKey] = data.errors[questionId];
    });
    return formErrors;
  };

  const handleForm = (formData: $TSFixMe) => {
    if (formData.current.state.isSubmitting) {
      return {};
    }

    formData.current.state.isSubmitting = true;
    return respondToSurvey(
      userId,
      surveyId,
      emailId,
      attribution,
      formData.current.state.values,
    ).then(function (data: $TSFixMe) {
      if (data.errors) {
        formData.current.state.isSubmitting = false;
        return {
          errors: getErrorsFromData(data),
        };
      }
      if (isFeatureEnabled('embedded_surveys')) {
        if (data.survey_status === 'off') {
          setIsClosed(true);
          return {};
        }
      }
      formData.current.state.isSubmitting = false;
      setCompleted(true);
      return {};
    });
  };

  const handleEditResponse = (formData: $TSFixMe) => {
    const { isSubmitting } = formData.current.state;
    const surveyFormData = formData.current.state.values;
    const { questions = null } = surveyFormData;
    if (isSubmitting || !questions || questions.length === 0) {
      return;
    }
    if (changeStack[stackTarget].current instanceof Promise) {
      changeStack[stackTarget].changes.push(formData);
      return changeStack[stackTarget].current.then(() => {
        if (changeStack[stackTarget].changes.length > 0) {
          changeStack[stackTarget].current = null;
          const change =
            changeStack[stackTarget].changes[
              changeStack[stackTarget].changes.length - 1
            ];
          changeStack[stackTarget].changes = [];
          handleEditResponse(change);
        } else {
          changeStack[stackTarget] = changeStackDefault();
        }
      });
    }
    changeStack[stackTarget].current = validateResponse(
      userId,
      surveyId,
      emailId,
      attribution,
      surveyFormData,
    ).then(function (data: $TSFixMe) {
      const formErrors = [];
      if (data.errors) {
        formErrors.push(getErrorsFromData(data));
        formData.current.state.errors = formErrors[0];
      } else {
        formErrors.push({});
      }
      if (data.surveyStatus === 'off') {
        setIsClosed(true);
        return Promise.resolve();
      }
      if (JSON.stringify(formErrors[0]) !== JSON.stringify(errors)) {
        setErrors(formErrors[0]);
      }
      return Promise.resolve();
    });
    return changeStack[stackTarget].current;
  };

  let body;
  if (isClosed) {
    body = <ClosedMessage survey={surveyData} />;
  } else if (isCompleted) {
    body = (
      <Fragment>
        <CompletionMessage survey={surveyData} />
        {/*
         // @ts-expect-error TS2322 */}
        <TrackingImage tracker={completeTracking} />
      </Fragment>
    );
  } else {
    body = (
      <Fragment>
        <SurveyHeader survey={surveyData} />
        {isFeatureEnabled('embedded_surveys') ? (
          !isLoading && (
            <SurveyForm
              survey={surveyData}
              onSubmit={handleForm}
              handleEditResponse={handleEditResponse}
              responseData={queryData}
            />
          )
        ) : (
          <SurveyForm
            survey={surveyData}
            onSubmit={handleForm}
            handleEditResponse={() => {}}
          />
        )}
      </Fragment>
    );
  }

  return (
    <div className={surveyVariables.root}>
      <SurveyContainer survey={surveyData}>{body}</SurveyContainer>
      {/*
       // @ts-expect-error TS2322 */}
      <TrackingImage tracker={openTracking} />
    </div>
  );
};

export default App;
